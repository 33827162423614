<template>
	<div class="col-12 col-md-4 pb-4 text-center" v-on:click="goToItem(id)" style="cursor:pointer">
		<img class="img-fluid" v-bind:src="imgSrc" data-aos="flip-left"/> 
		<h3 class="text-center text-white pt-4">{{ $t(title) }}</h3>
	</div>
</template>
<script>
export default {
  name: 'OfferItem',
  props: ['imgSrc','title','text','id'],
  methods: {    
  	goToItem() {
  	 	this.$router.push({name:'item',params:{'id':this.id}})
  	}
  }
}
</script>