<template>	  
	  <div class="offer" id="offer">
	  	<Masthead :height="300" :noLetters="true" />
	    <section class="">
		     <div class="container">
		      <div class="row pb-md-2" >    
		        <div class="col-12">
		          <h2 class="pt-4 text-center text-white"  data-aos="fade">{{ $t('offer') }}</h2>
		          <br>
		        </div>
		      </div>
		      <div class="row pt-2 justify-content-md-center">    		        
		      	<OfferCard v-for="(item, index) in offerItems" :key="index" :text="item.text" :title="item.title" :imgSrc="item.imgSrc" :id="item.id" />
		      </div>
		    </div>
		  </section>
	  </div>
</template>
<script>
import Masthead from '@/components/Masthead.vue';
import OfferCard from '@/components/OfferCard.vue';
import offer from '../api/offer';
export default {
  name: 'Offer',
   data: function () {
   return {
      offerItems: offer
    }
  },
  metaInfo() {
  	return{
  		title: this._i18n.t('StalmatOffer'),
	    meta: [
		      { 
		      	name: 'description', 
		      	content: 'STALMAT - Firma STALMAT powstała w 2005 roku. Głównym przedmiotem naszej działalności jest produkcja najwyższej jakości śrubowych elementów złącznych wykonanych według norm oraz dostarczonej dokumentacji i rysunków' 
		      },
		      { 
		      	name: 'keywords', 
		      	content: 'stalmat,śruby,śruby dwustronne,nakrętki,podkładki,sworznie' 
		      },
		      { 
		      	name: 'author', 
		      	content: 'https://www.itfrog.pl' 
		      },
		      { 
		      	name: 'robots', 
		      	content: 'index,follow' 
		      },
		    ]
  	}
  },
  components: {
     Masthead,OfferCard
  },
  created: function(){
  },
}
</script>