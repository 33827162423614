export default {
      0:{
          id:0,
          text: 'screw1txt',
          title: 'screw1',
          imgSrc: require('@/assets/img/ŚRUBY DWUSTRONNE.jpg'),
        },
      1:{
          id:1,
          text: 'screw2txt',
          title: 'screw2',
          imgSrc: require('@/assets/img/screw1.jpg'),
        },
      2:{
          id:2,
          text: 'screw3txt',
          title: 'screw3',
          imgSrc: require('@/assets/img/NAKRĘTKI.jpg'),
        },
      3:{
          id:3,
          text: 'screw4txt',
          title: 'screw4',
          imgSrc: require('@/assets/img/podkladka.jpg'),
        },
      4:{
          id:4,
          text: 'screw5txt',
          title: 'screw5',
          imgSrc: require('@/assets/img/sworznie.jpg'),
        }
    }